<template>
  <div>
    <p>Budgets works!</p>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Budgets",
  components: {},
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped></style>
